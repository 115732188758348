@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-textDefault;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Roboto;
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

.font-Roboto {
  font-family: Roboto;
}




@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/AeonikProTRIAL-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/AeonikProTRIAL-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/AeonikProTRIAL-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/AeonikProTRIAL-Bold.otf') format('opentype');
}


.bgImage {
  position: relative;

}
.bgImage > * {
  z-index: 1;
  position: relative;
}
.bgImage:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/bg.png');
  opacity: 0.2;
  z-index: 0;
}

h2 {
  background: linear-gradient(180deg, #FFFFFF 9.41%, #999999 90.32%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h1 {
  background: linear-gradient(180deg, #FFFFFF 9.41%, #999999 43.86%, #FFFFFF 59.51%, #999999 90.32%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 1024px) {
  h1 {
      background: linear-gradient(180deg, #FFFFFF 9.41%, #999999 93.86%, #FFFFFF 59.51%, #999999 90.32%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }

}

.sectionTitle {
  background: linear-gradient(180deg, #202020 0%, #1D1D1D 100%);
  position: relative;
}

.customBorder{
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #494848 0%, #1E1E1E 100%);
  z-index: -1;
}
.smallBroder{
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #4E4E4E 0%, #4E4E4E 100%);
  z-index: -1;
}

.customBorderCard{
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  height: 100%;
  background: linear-gradient(180deg, #494848 0%, #1E1E1E 50%);
  z-index: -1;
}

.sectionTitle h4 {
  background: linear-gradient(90deg, #FFFFFF 1.04%, #999999 98.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


